import * as React from 'react'
import Layout from '/src/components/layout'

const TackPage = () => {
    return (
        <Layout pageTitle="Licenser, attribut &amp; tack!">
            
			<p class="intro">Även om mycket tid och engagemang ligger bakom Laddify så har det underlättats av några fantastiska projekt. Tack!</p>
            
			<h2>Logga</h2>
			<p>Laddifys snygga logga är designad av <a href="https://www.linkedin.com/in/josefine-bergkvist" target="_blank" rel="noreferrer">Josefine Bergkvist</a>.</p>


			<h2>Ikoner</h2>
			<ul>
				<li>CCS: <a href="https://commons.wikimedia.org/wiki/File:Combo-ccs-eu.svg" target="_blank" rel="noreferrer">GreatDrok</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0" target="_blank" rel="noreferrer">CC BY-SA 4.0</a>, via Wikimedia Commons</li>
				<li>Schuko: <a href="https://commons.wikimedia.org/wiki/File:CEE7_Type4.svg" target="_blank" rel="noreferrer">Gabor111a</a>, CC0, via Wikimedia Commons</li>
				<li>Typ 2: <a href="https://commons.wikimedia.org/wiki/File:Type_2_mennekes.svg" target="_blank" rel="noreferrer">GreatDrok</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0" target="_blank" rel="noreferrer">CC BY-SA 4.0</a>, via Wikimedia Commons</li>
				<li>CHAdeMO: <a href="https://commons.wikimedia.org/wiki/File:Chademo_type4.svg" target="_blank" rel="noreferrer">GreatDrok</a>, <a href="https://creativecommons.org/licenses/by-sa/4.0" target="_blank" rel="noreferrer">CC BY-SA 4.0</a>, via Wikimedia Commons</li>
				<li>Och sist men absolut inte minst, en mängd knivskarpa ikoner från brilljanta <a href="https://fontawesome.com" target="_blank" rel="noreferrer">Font Awesome</a>.</li>
			</ul>


			<h2>Bibliotek &amp; ramverk</h2>
			<ul>
				<li>Blixtsnabb webbsida tack vare <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">Gatsby</a>.</li>
				<li>Vackra kartor från <a href="https://www.openstreetmap.org" target="_blank" rel="noreferrer">OpenStreetMap</a>.</li>
				<li>Enkel karthantering med <a href="https://leafletjs.com" target="_blank" rel="noreferrer">Leaflet</a> och tillägg från XXX.</li>
			</ul>
        

			<h2>Laddnätverk</h2>
			<p>Och såklart tack till alla nätverk som samtycker till att deras information får samlas in och visas i Laddify. Samt även tack till databasen <a href="https://info.nobil.no" target="_blank" rel="noreferrer">Nobil.no</a> vilket används för några nätverk som inte har möjlighet till egna API:er. Källa för respektive nätverk framgår på respektive stationssida.</p>


            <h2>Gatuvyer</h2>
            <p>På stationssidan visas i de fall det är möjligt en gatuvy från <a href="https://www.google.com/maps" target="_blank" rel="noreferrer">Google Street View</a>.</p>


            <h2>Närservice</h2>
            <p>Via <a href="https://www.google.com/maps" target="_blank" rel="noreferrer">Google Maps</a> hämtas verksamheter som är i närheten av respektive laddstation. Verksamheterna visas på stationssidan men det går även att filtrera stationer efter avstånd till olika typer av verksamheter, exempelvis matställe.</p>


        </Layout>
    )
}

export default TackPage